import { Injectable, OnDestroy } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { onboardingComplete, UserService } from '../user.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardingGuard implements CanActivate {
  private onboarding: Observable<boolean>;

  constructor(userService: UserService, private router: Router) {
    this.onboarding = userService
      .getProfile()
      .pipe(map((p) => onboardingComplete(p)));
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.onboarding.pipe(
      tap((status) => {
        if (status == false) {
          this.router.navigate(['start']);
        }
      }),
      catchError((e) => this.router.navigate(['start']))
    );
  }
}
