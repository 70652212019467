import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { throwIfAlreadyLoaded } from './guard/module-import.guard';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { UserService } from './user.service';
import { OnboardingGuard } from './guard/onboarding.guard';
import { LoaderInterceptor } from './interceptor/http-activity.interceptor';
import { LoaderService } from './loader.service';
// import { ErrorInterceptor } from './interceptor/error.interceptor';

@NgModule({
  declarations: [],
  imports: [HttpClientModule],
  providers: [
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ErrorInterceptor,
    //   multi: true,
    // },
    UserService,
    OnboardingGuard,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
