import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { KeycloakService, KeycloakBearerInterceptor } from 'keycloak-angular';

@Injectable()
export class TokenInterceptor extends KeycloakBearerInterceptor {}
