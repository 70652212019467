import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { zip, from, BehaviorSubject, Observable, throwError, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

export class ServerProfile {
  companyName?: string;
  mobileNumber?: string;
  type?: string;
}

interface UserProfile extends ServerProfile, KeycloakProfile {}

export class Profile implements UserProfile {
  // Server Profile fields
  companyName?: string;
  mobileNumber?: string;
  type?: string;
  // Keycloak Profile fields
  id?: string;
  username?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  enabled?: boolean;
  emailVerified?: boolean;
  totp?: boolean;
  createdTimestamp?: number;

  deleted?: boolean;
  createdBy?: string;
  updatedBy?: string;
  createdOn?: Date;
  updatedOn?: Date;
}

export function onboardingComplete(profile: Profile): boolean {
  // TODO: change server profile to resolve this from server instead
  if (profile.companyName && profile.mobileNumber && profile.type) {
    // all values must be set
    return true;
  }
  return false;
}

@Injectable()
export class UserService {
  private static headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json');

  public readonly isLoading = new BehaviorSubject(false);
  private profile = new BehaviorSubject<Profile>(new Profile());
  private onboarding$: Observable<boolean>;

  constructor(
    private readonly keycloak: KeycloakService,
    private httpClient: HttpClient
  ) {
    this.isLoading.next(true); // mark service profile loading
    this.onboarding$ = this.profile
      .asObservable()
      .pipe(map((p) => onboardingComplete(p)));
    this.load(); // trigger user profile preparation
  }

  private async load() {
    const isLoggedIn = await this.keycloak?.isLoggedIn();
    if (isLoggedIn) {
      // If logged in, user must be created if missing
      const kcProfile = this.keycloak.loadUserProfile();
      zip(this.loadServerProfile(), from(kcProfile))
        .pipe(
          map((val) => {
            return { ...val[0], ...val[1] } as Profile;
          })
        )
        .subscribe(
          (user) => {
            this.profile.next(user); // update subject with next
          },
          (err) => {
            this.profile.error(err);
            this.isLoading.next(false);
          }, // error out the subject
          () => this.isLoading.next(false)
        );
    }
  }

  private loadServerProfile() {
    const url = `${environment.api.base}/user`;
    return this.httpClient.get<ServerProfile>(url).pipe(
      catchError((error) => {
        let errorMsg: string;
        if (error.error instanceof ErrorEvent) {
          // client side error
          errorMsg = `Error: ${error.error.message}`;
        } else {
          // server side error handling
          const httpError = error as HttpErrorResponse;
          if (httpError.status === 404) {
            // normal stuff, continue with an empty ServerProfile object
            return of(new ServerProfile());
          }
          errorMsg = this.getServerErrorMessage(error);
        }
        return throwError(errorMsg);
      })
    );
  }

  updateServerProfile(serverProfile: ServerProfile) {
    const url = `${environment.api.base}/user`;
    return this.httpClient.post<ServerProfile>(url, serverProfile).pipe(
      tap(() => {
        let profileState = this.profile.getValue();
        this.profile.next({
          ...profileState, // old state
          ...serverProfile, // update with new profile attributes (persisted on server)
        });
        // trigger load
        this.load(); // refresh the profile here
      })
    );
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Unknown Server Error: ${error.message}`;
      }
    }
  }

  getProfile(): Observable<Profile> {
    return this.profile.asObservable();
  }

  onboardingState() {
    return this.onboarding$;
  }
}
